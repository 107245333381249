import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import Layout from "../components/layout";

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const { timeline, metaDescription } = frontmatter;
  const { timelineItems } = timeline;
  const breadcrumbs = [
    { name: "Home", href: "/" },
    { name: frontmatter.title },
  ];
  return (
    <Layout
      breadcrumbs={breadcrumbs}
      pageTitle={frontmatter.title}
      preambleText={frontmatter.preambleText}
      metaDescription={metaDescription}
    >
      <PageContent>
        <Content dangerouslySetInnerHTML={{ __html: html }} />
        <Timeline>
          <h2>{timeline.heading}</h2>
          <TimelineContainer>
            {timelineItems.map((timelineItem) => (
              <TimelineItem key={timelineItem.year}>
                {timelineItem.image &&
                  timelineItem.image.childImageSharp &&
                  timelineItem.image.childImageSharp.fluid && (
                    <ImageContainer>
                      <Img
                        css="height: 100%;"
                        fluid={timelineItem.image.childImageSharp.fluid}
                      />
                    </ImageContainer>
                  )}
                <TextBlock>
                  <TimelineHeading>{timelineItem.year}</TimelineHeading>
                  <TimelineText>{timelineItem.text}</TimelineText>
                </TextBlock>
              </TimelineItem>
            ))}
          </TimelineContainer>
        </Timeline>
      </PageContent>
    </Layout>
  );
}

const PageContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -4vw;
`;

const Timeline = styled.div`
  flex-basis: 50%;
  flex-grow: 1;
  min-width: 280px;
  padding: 4vw;
  overflow: hidden;

  @media (min-width: 24em) {
    min-width: 20em;
  }
`;

const TimelineContainer = styled.div`
  position: relative;
  margin-top: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 0.25rem;
    height: 100%;
    top: 0;
    left: 50%;
    margin-left: -0.125rem;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 2%,
      rgba(255, 255, 255, 0.5) 98%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  @media (min-width: 32em) {
    margin-left: 2rem;
    padding-left: 2rem;

    &::before {
      left: 0;
    }
  }
`;

const TimelineItem = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 4rem;
  /* border: 1px solid ${({ theme }) => theme.palette.white}; */
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.palette.grey};
  color: ${({ theme }) => theme.palette.softWhite};
  z-index: 1;

  &:first-child {
    margin-top: 2rem;
  }

  &:last-child {
    margin-bottom: 2rem;

    &::before {
      content: "";
      display: block;
      position: absolute;
      height: 3.5rem;
      left: 50%;
      bottom: -4rem;
      margin-left: -0.125rem;
      border-left: 0.25rem dashed ${({ theme }) => theme.palette.dark};
    }
  }

  @media (min-width: 32em) {
    margin-top: 2rem;
    margin-left: 2rem;

    &::after {
      content: "";
      display: block;
      position: absolute;
      left: -2.5rem;
      top: 25%;
      width: 2.5rem;
      height: 1px;
      background-color: ${({ theme }) => theme.palette.white};
    }

    &:first-child {
      margin-top: 0rem;
    }

    &:last-child {
      margin-bottom: 2rem;

      &::before {
        height: 100%;
        left: -4.125rem;
        top: 25%;
        margin-left: 0;
      }
    }
  }
`;

const ImageContainer = styled.div`
  flex-basis: 10rem;
  flex-grow: 1;
  border-radius: 1rem;
  overflow: hidden;
`;

const TextBlock = styled.div`
  flex-grow: 2;
  flex-basis: 25rem;
  max-width: 60ch;
  padding: 1rem;
`;

const TimelineHeading = styled.h3`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 50%;
  width: 5rem;
  height: 5rem;
  margin: 0;
  margin-top: -2.5rem;
  margin-left: -2.5rem;
  border: 0.625rem solid #44d62c;
  box-shadow: 0 0 0 0.375rem #000;
  font-size: 1em;
  font-weight: normal;
  border-radius: 50%;
  background-color: #000;
  z-index: 1;

  @media (min-width: 32em) {
    left: -4rem;
    top: 25%;
  }
`;

const TimelineText = styled.p`
  margin: 0;
`;

const Content = styled.div`
  flex-basis: 50%;
  flex-grow: 1;
  max-width: 60ch;
  padding: 4vw;

  .gatsby-resp-image-wrapper,
  img {
    display: block;
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
  }
`;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        preambleText
        metaDescription
        timeline {
          heading
          timelineItems {
            year
            text
            image {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
